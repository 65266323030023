<template>
  <div>
    <section class="section is-main-section">
      <div class="columns">

        <div class="column is-one-third">
          <card-component
            title="1. Select the datasets to integrate"
            class="tile is-child"
            :displayHelp="$options.name"
          >
            <validation-observer v-slot="{ handleSubmit }">
              <validation-provider
                v-slot="{ errors, valid }"
                :rules= {required:true,twoDatasets:true}
                :customMessages = "{twoDatasets: 'Select at least two datasets to integrate.'}"
                name="datasets"
              >
                <b-field
                  label="Datasets"
                  :message="errors"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-taginput
                    v-model="datasets"
                    :data="filteredDatasets"
                    autocomplete
                    rounded
                    field="user_name"
                    :open-on-focus="true"
                    :allow-new="false"
                    icon="caret-right"
                    placeholder="Add a dataset"
                    :maxtags="nbDataset"
                    :has-counter="false"
                    @typing="getFilteredDatasets"
                  > </b-taginput>
                </b-field>
              </validation-provider>
              <hr />
              <div class="has-text-centered">
                <b-button rounded
                  class="is-primary button-shadow"
                  @click.prevent="handleSubmit(run)"
                >
                  <span class="icon is-small"><i class="fa fa-search"></i></span>
                  <span> Integrate </span>
                </b-button>
              </div>
            </validation-observer>
          </card-component>
        </div>
        <div class="column">
          <card-component title="2. Plots and summaries" />
           <error :type='"notifications"' />
           <card-no-result v-if="!hasResults">
          </card-no-result>
          <div v-if="upsetJS">
            <card-component>
              <b-tabs @input="displayResult">
                <b-tab-item label="General information" icon="table" value="generalInfo" />
                <b-tab-item v-if="venn" label="Venn diagram" icon-pack="icomoon" icon="venn" value="venn" />
                <b-tab-item v-if="upsetJS" label="Upset plot" icon-pack="icomoon" icon="upsetjs" value="upsetjs" />
              </b-tabs>
            </card-component>
            <card-component
              v-if="currentResult==='generalInfo'"
              :title="generalInfos[0].title"
            >
              <general-info :data="generalInfos[0]"/>
            </card-component>
            <card-component
              v-if="currentResult==='generalInfo'"
              :title="generalInfos[1].title"
            >
              <general-info :data="generalInfos[1]"/>
            </card-component>
            <card-component v-show="currentResult==='venn'">
              <venn ref="venn" :data="venn"/>
            </card-component>
            <card-component v-if="currentResult==='upsetjs'">
              <upset-js :data="upsetJS"/>
            </card-component>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'

export default {
  name: 'RCombineDataset',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'venn': () => import('@/components/rresults/Venn.vue'),
    'upset-js': () => import('@/components/rresults/UpsetJs.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'general-info': () => import('@/components/rresults/GeneralInfo.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  mixins: [ranalysis],
  props: {
    minNumericVariable: {
      type: Number,
      default: 0
    },
    minSample: {
      type: Number,
      default: 0
    },
    nbDataset: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      datasets: [],
      filteredDatasets: this.allDatasets,
      currentResult: 'generalInfo'
    }
  },
  computed: {
    allDatasets: function () {
      return this.workspaceStore.datasets.filter(obj => obj.meta.nrow >= this.minSample && obj.meta.nbnum >= this.minNumericVariable)
    },
    params: function () {
      return {
        'func_name': 'r_combine_datasets',
        'datasetNames': this.datasets.map(obj => obj.object_name)
      }
    }
  },
  methods: {
    getFilteredDatasets (text) {
      this.filteredDatasets = this.allDatasets.filter((option) => {
        return option.user_name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    displayResult: function (value) {
      this.currentResult = value
      if (value === 'venn') {
        this.$refs.venn.render()
      }
    },
    beforeRun: function () {
      this.$emit('reset')
    },
    afterRun: function (data) {
      this.currentResult = 'generalInfo'
      if (this.hasResults) {
        this.$emit('is-combined', {
          'combinedObjectName': data.ObjectName,
          'combinedDatasets': this.datasets
        })
      }
    }
  },
  watch: {
    allDatasets: function () {
      this.filteredDatasets = this.allDatasets
    }
  },
  mounted () {
    this.getFilteredDatasets('')
    let self = this
    extend('twoDatasets', {
      getMessage: field => 'Select at least two datasets to integrate',
      validate: value => self.datasets.length > 1
    })
    if (this.analysisObj !== null) {
      if (this.analysisObj.origin_dataset.length > 1) {
        this.datasets = []
        this.analysisObj.origin_dataset.forEach(d => {
          this.datasets.push(this.allDatasets.filter(obj => obj.object_name === d)[0])
        })
      }
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
